<template>
  <div class="page-content">
    <page-breadcrumb title="User Stage" class="mb-2" />
    <div class="d-flex justify-content-start align-items-center">
        <form-generator :schema="filter_schema" :model="filter" style="width: 100%" />
    </div>
    <data-table-ssr id="group_list" ref="group_list"
      :columns="fields" :get-list-fn="getList"
      :edit-row-fn="updateItem"
    />
  </div>
</template>

<script>
import service from '../service'

const fields = [
  { label: 'Name', field: 'name' },
  { label: 'Phone', field: 'phone' },
  { label: 'Email', field: 'email' },
  { label: 'Stage', field: 'stage', editable: true}
];
const filter_schema = [
  { cols: 2, fields: [{ label: 'Email', field: 'email', input_type: 'email' }] },
  { cols: 2, fields: [{ label: 'Phone', field: 'phone' }] },
];
export default {
  data(){
    return{
      fields,
      filter_schema,
      filter: { email: null, phone: null }
    }
  },
  watch: {
    filter: {
      handler() {
        this.$refs.group_list.getList();
      },
      deep: true,
    },
  },
  methods:{
    async createItem(data) {
      let new_item = await service.create({ ...data });
      return new_item;
    },
    async getList({ limit, page }) {
      let response_data = await service.getList({
        query: JSON.stringify(this.filter),
        page, limit
      });
      let list = [], total = 0;
      if (response_data) {
        list = response_data;
      }
      return { list, total };
    },
    async updateItem(row){
			await service.update(row);
		}
  }
}
</script>
