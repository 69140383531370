import api from '@/libs/axios'

export default {
  async getList(params) {
    let response = await api.get('/admin/users/user-progress', {params});
    return response.data.data
  },
  async update(payload) {
    let response = await api.post('/admin/users/update-stage', payload)
    return response
  },
}
